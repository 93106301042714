<template>
  <div>
    <input type="file" name="fileToUpload" id="fileToUpload" @change="FileChosen">
  </div>
</template>

<script>
import axios from "axios";
import Config from '@/config/index.js'

export default {
  name: "FileUpload",
  props: {
    type: String
  },
  methods: {
    async FileChosen(x) {
      const reader = new FileReader();
      reader.onload = () => {
        let base64result = reader.result.split(',')[1];
        let payload = {name: x.target.files[0].name, type: this.type, body: base64result};

        let token = sessionStorage.getItem("tkn");
        axios
        .post(
            `${Config.baseAPIPath}/SaveFile`, 
            payload, 
            {headers : { 'Access-Control-Allow-Origin': '*', "Authorization": `Bearer ${token}` }})
        .then(() => {
            this.$emit('file-uploaded');
          })
      };
      reader.readAsDataURL(x.target.files[0]);
    }
  }
}
</script>