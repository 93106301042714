import axios from "axios";
import Config from '@/config/index.js'
import store from '@/store'
const LoadImages = async () => {
  const token = store.getters.token;

  var result = await axios
    .get(
      `${Config.baseAPIPath}/ListImages`,
      { headers: { "Access-Control-Allow-Origin": "*", "Authorization": `Bearer ${token}` } }
    );
  return result.data;
}

const DeleteImage = async (name) => {
  const token = store.getters.token;

  var result = await axios
  .post(
    `${Config.baseAPIPath}/DeleteFile`,
    {"name": name, "type": "image"},
    { 
      headers: { "Access-Control-Allow-Origin": "*", "Authorization": `Bearer ${token}` },
    }
  );
  return result.data;
}

export default {
  LoadImages,
  DeleteImage
}