<template>
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
    <router-link :to="{ name: 'Admin' }">
      <div class="Crumb">
        <i class="material-icons icon">arrow_back</i>
        Back to Admin...
      </div>
    </router-link>
  <hr>
</template>

<style scoped>
  .Crumb {
    display: inline-flex;
    border: 1px solid white;
    transition: border 0.2s ease;
    box-sizing: border-box;
    padding: 2px 5px;
  }
  .icon {
    margin-right: 6px;
    text-decoration: none;
    overflow: auto;
    transition: color 0.2s ease;

  }
  .Crumb:hover {
    border: 1px solid #ccc;
    
  }
  .Crumb:hover > i.icon {
    color: red;
  }
</style>