<template>
  <PageContent>
    <SubHeading>Image Management</SubHeading>
    <AdminBreadCrumb />
    <div class="fileUpload">
      <span>Would you like to upload a new image: </span>
      <FileUpload type="image" @file-uploaded="ImageLoaded" />
    </div>
    <ImageList v-if="imagesLoaded" :images="images" :allowDelete="true" @delete="DeleteImage" />

  </PageContent>
</template>

<style scoped>

</style>

<script>
import PageContent from '@/components/PageContent'
import SubHeading from '@/components/SubHeading'
import ImageList from '@/components/ImageList'
import FileUpload from '@/components/FileUpload'
import AdminBreadCrumb from '@/components/Admin/AdminBreadCrumb'

import ImageService from '@/services/ImageService'

export default {
  name: "EditCards",
  components: {
    PageContent,
    SubHeading,
    ImageList,
    FileUpload,
    AdminBreadCrumb
  },
  data() {
    return {
      images: [],
      imagesLoaded: false
    }
  },
  async mounted() {
    await this.LoadImages();
  },
  computed: {
  },
  methods: {
    async LoadImages() {
      this.images = await ImageService.LoadImages();
      this.imagesLoaded = true;
    },
    async ImageLoaded () {
      await this.LoadImages();
    },
    async DeleteImage(name) {
      if (!confirm(`Are you sure you want to delete ${name} ?`)) {return;}
      await ImageService.DeleteImage(name);
      await this.LoadImages();
    }
  }
}
</script>