<template>
  <div>
    <div class="images">
      <div class="image" v-for="image in images" :key="image" @click="imageClicked(image)">
        <div>
          {{image}}
        </div>
        <img :src="`/images/${image}`" :alt="image">
        <button v-if="allowDelete" @click.stop="$emit('delete',image)">Delete</button>
      </div>
    </div>
  </div>

</template>

<style scoped>
  .images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .image {
    width: 150px;
    padding: 5px;
    background-color: rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    border-radius: 10px;
  }
  .image:hover {
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
    color: red;
    cursor: pointer;
  }
  .image > img {
    max-width: 100px;
    max-height: 100px;
  }
  label {
    margin-right: 10px;
  }
  select {
    font-size: 1.2rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    border-color:rgba(0,0,0,0.1)
  }
  .options {
    padding: 20px;
  }
  button {
    margin-top: 5px;
  }
</style>

<script>
export default {
  props: {
    images: Array,
    allowDelete: Boolean
  },
  methods: {
    imageClicked (name) {
      this.$emit("image-clicked", {name, insertType: this.insertType})
    }
  }
}
</script>